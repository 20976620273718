.close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--background-highlight);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
