.buttonBase {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  padding: 10px 15px !important;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  height: fit-content;
  line-height: 21px;
  position: relative;
}

.button {
  background-color: var(--background-button);
  border: 1px solid var(--border-modal);
  color: var(--text-lighter);
}

.button:focus {
  outline: none !important;
}

.shadow {
  box-shadow: var(--shadow-modal);
}

.buttonAccent,
.buttonAccent[disabled] {
  border: none;
  background-color: var(--accent);
  color: var(--text-on-accent) !important;
  /* background-color: rgb(125, 68, 250); */
  /* background-image: linear-gradient(90deg, rgb(143, 45, 240), rgb(60, 92, 207)); */
}


.buttonAccent:hover {
  background-color: var(--primary-500);
}

.buttonAccentBorder,
.buttonAccentBorder[disabled] {
  background: none;
  border: 1px solid var(--accent);
  color: var(--accent);
  /* background-color: rgb(125, 68, 250); */
  /* background-image: linear-gradient(90deg, rgb(143, 45, 240), rgb(60, 92, 207)); */
}


.buttonAccentBorder:hover {
  background-color: var(--accent-light);
  border: 1px solid var(--accent);
  color: var(--accent);
  /* background-color: rgb(125, 68, 250); */
  /* background-image: linear-gradient(90deg, rgb(143, 45, 240), rgb(60, 92, 207)); */
}

.buttonAccent a {
  color: var(--text-on-accent) !important;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
  border: 1px solid var(--border-modal);
  color: var(--text-lighter);
}

.small {
  border-radius: 4px;
  padding: 5px 12px !important;
  height: auto;
}

.buttonBlack,
.buttonBlack[disabled] {
  background-color: var(--background-invert) !important;
  ;
  border: none;
  color: var(--text-invert) !important;
  ;
}

.buttonBlack:hover {
  background-color: var(--background-invert) !important;
  ;
  color: var(--text-invert) !important;
  ;
}

.notifTopRight {
  position: absolute;
  left: 100% !important;
  top: 0px !important;
}

.loading {
  color: rgba(0, 0, 0, 0);
}

.accentLoader {
  position: absolute;
  left: calc(50% - (1em * 0.5));
  top: calc(50% - (1em * 0.5));
}

.buttonRounded {
  border-radius: 999px;
}

.buttonOutlineBorder,
.buttonOutlineBorder[disabled] {
  background: none;
  border: 1px solid var(--color-border);
  color: var(--neutral-700);
}

[data-theme="dark"] .buttonOutlineBorder,
.buttonOutlineBorder[disabled] {
  background: var(--color-surface-raised);
  border: 1px solid var(--color-border-light);
  color: var(--neutral-900)
}

.buttonOutlineBorder:hover {
  background-color: var(--neutral-50);
  border: 1px solid var(--neutral-300);
  color: var(--accent);
}
