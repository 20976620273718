.defaultOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 85vw;
  max-height: 85vh;
  overflow: hidden;
  background: var(--background-modal);
  display: flex;
  flex-direction: column;
  outline: none;
  border-radius: 8px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--background-highlight);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.dialogHeader {
  border-bottom: 1px solid var(--border-color);
  padding: 1.3em;
}

.dialogModalContent {
  overflow-x: hidden;
  padding: 1.2em;
  flex-grow: 1;
  flex-shrink: 1;
}

.dialogFooter {
  border-top: 1px solid var(--border-color);
  flex-shrink: 0;
  padding: 1.2em;
}
