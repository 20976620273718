@charset "utf-8";

// COLORS
// $primary: #21ce99;
$primary: #5E5DF0;
$dark-primary: #4545d4;
$info: #209cee;
$success: #21ce99;
$warning: #fa9e34;
$danger: #ff3860;
$light: #f5f5f5;
$dark: #1f2023;

// TEXT
$text: inherit;
$link: #7979ff;

// $body-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
//   "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
//   sans-serif;
$body-family: Inter, "Helvetica", "Apple Color Emoji", "Segoe UI Emoji",
  NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
  EmojiSymbols, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
  "Helvetica Neue", "Noto Sans", sans-serif;

$title-family: false;
$button-family: false;

$title-color: #282a30;
$subtitle-color: #4a4574;
$text-strong: inherit;
$strong-color: inherit;
$title-weight: 500;
$body-line-height: 24px;
$title-weight: 500;
$box-background-color: #ffffff;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

// CONTENT
$content-blockquote-background-color: inherit;
$content-blockquote-padding: 0.25em 1em;

// NAVBAR HEIGHTS
$navbar-item-img-max-height: 3rem;

// INPUTS
$input-placeholder-color: 'rgba(0,0,0,0.5)';

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O
// IMPORT BULMA
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/box.sass";
@import "~bulma/sass/elements/button.sass";
// @import "~bulma/sass/elements/content.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/elements/tag.sass";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/components/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all";
@import "~bulma-switch";
