a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  /* outline: 1px solid red; */
}

audio:focus,
video:focus {
  outline: none;
}

/*
background
text-primary
text-secondary
border
border:hover */



:root {
  /* Color styles from figma */
  --neutral-25: #fff;
  --neutral-50: #FAFAFA;
  --neutral-100: #F5F5F5;
  --neutral-200: #EBEBEB;
  --neutral-300: #E0E0E0;
  --neutral-400: #CCCCCC;
  --neutral-500: #AAAAAA;
  --neutral-600: #808080;
  --neutral-700: #555555;
  --neutral-800: #2A2A2A;
  --neutral-900: #000;
  --primary-25: #F4F4FF;
  --primary-30: #E0DFFC;
  --primary-50: #DFDFFC;
  --primary-100: #AEAEF7;
  --primary-200: #9493F4;
  --primary-300: #7978F2;
  --primary-400: #5E5DEF;
  --primary-500: #4E4EC7;
  --primary-600: #3f3e9f;
  --primary-700: #2f2f78;
  --primary-800: #1f1f50;
  --primary-900: #131330;
  --error-25: #fed5d5;
  --error-50: #fdbaba;
  --error-100: #fc9797;
  --error-200: #fb7474;
  --error-300: #fa5252;
  --error-400: #FF3860;
  --error-500: #d02727;
  --error-600: #a61f1f;
  --error-700: #7d1818;
  --error-800: #531010;
  --error-900: #320909;
  --warning-25: #ffefd3;
  --warning-50: #fee5b5;
  --warning-100: #fed790;
  --warning-200: #feca6c;
  --warning-300: #fdbd47;
  --warning-400: #fdb022;
  --warning-500: #d3931c;
  --warning-600: #a97517;
  --warning-700: #7f5811;
  --warning-800: #543b0b;
  --warning-900: #332307;
  --success-25: #d0f1e1;
  --success-50: #d0f1e1;
  --success-100: #88dbb5;
  --success-200: #61cf9c;
  --success-300: #39c383;
  --success-400: #12B76A;
  --success-500: #0f9858;
  --success-600: #0c7a47;
  --success-700: #095c35;
  --success-800: #063d23;
  --success-900: #042515;
  --red-25: #fdd5df;
  --red-50: #fbb9ca;
  --red-100: #f997b0;
  --red-200: #f77495;
  --red-300: #f5517b;
  --red-400: #f32e60;
  --red-500: #ca2650;
  --red-600: #a21f40;
  --red-700: #7a1730;
  --red-800: #510f20;
  --red-900: #310913;
  --orange-25: #feecd6;
  --orange-50: #fddfbb;
  --orange-100: #fdce9a;
  --orange-200: #fcbe78;
  --orange-300: #fbae56;
  --orange-400: #fa9e34;
  --orange-500: #d0842b;
  --orange-600: #a76923;
  --orange-700: #7d4f1a;
  --orange-800: #533511;
  --orange-900: #32200a;
  --green-25: #EEFBF8;
  --green-50: #b6efe0;
  --green-100: #91e7d1;
  --green-200: #6cdfc2;
  --green-300: #48d7b2;
  --green-400: #23cfa3;
  --green-500: #1dac88;
  --green-600: #178a6d;
  --green-700: #116751;
  --green-800: #0c4536;
  --green-900: #042515;
  --blue-25: #ccf3ff;
  --blue-50: #abeaff;
  --blue-100: #80e0ff;
  --blue-200: #56d5ff;
  --blue-300: #2ccbff;
  --blue-300: #02c1ff;
  --blue-400: #02c1ff;
  --blue-500: #02a1d5;
  --blue-600: #0181AA;
  --blue-700: #016080;
  --blue-800: #014055;
  --blue-900: #002733;
  --indigo-25: #dfdffc;
  --indigo-50: #c9c9fa;
  --indigo-100: #aeaef7;
  --indigo-200: #9493f4;
  --indigo-300: #7978f2;
  --indigo-400: #5e5def;
  --indigo-500: #4e4ec7;
  --indigo-600: #3f3e9f;
  --indigo-700: #2f2f78;
  --indigo-800: #1f1f50;
  --indigo-900: #131330;
  --purple-25: #e8d3ff;
  --purple-50: #d8b6ff;
  --purple-100: #c492ff;
  --purple-200: #b16dfe;
  --purple-300: #9d49fe;
  --purple-400: #8a24fe;
  --purple-500: #731ed4;
  --purple-600: #5c18a9;
  --purple-700: #45127f;
  --purple-800: #2e0c55;
  --purple-900: #1c0733;
  --pink-25: #FEF5F9;
  --pink-50: #fbc3db;
  --pink-100: #faa4c9;
  --pink-200: #f886b8;
  --pink-300: #f66ba6;
  --pink-400: #f44a94;
  --pink-500: #cb3e7b;
  --pink-600: #a33163;
  --pink-700: #7a254a;
  --pink-800: #511931;
  --pink-900: #310f1e;

  /* New color tokens */
  --color-border: var(--neutral-400);
  --color-border-light: var(--neutral-300);
  --color-text-light: var(--neutral-800);
  --color-surface-raised: var(--neutral-25);
  --color-background-input: var(--neutral-100);

  /* Your default theme */
  --background: #ffffff;
  --background-transparent: rgba(255, 255, 255, 0.5);
  --background-blur: rgba(255, 255, 255, 0.7);
  --background-highlight: #f0f3f9;
  --background-highlight-toolbar: #f0f3f9;
  --background-highlight-highlight: #e5eaf5;
  --background-toolbar: #ffffff;
  --background-toolbar-transparent: rgba(255, 255, 255, 0.8);
  --background-highlight-transparent: rgba(255, 255, 255, 0.7);
  --background-button: #ffffff;
  --background-modal: linear-gradient(136.61deg,
      rgb(255, 255, 255) 13.72%,
      rgb(255, 255, 255) 74.3%);
  --background-discount-modal: linear-gradient(180deg,
      rgb(255, 255, 255) 0%,
      rgb(239, 239, 255) 100%);
  --background-publish-purple: var(--primary-400);
  --background-modal-highlight: rgb(248, 249, 251);
  --background-popup-overlay: rgba(144, 149, 157, 0.4);
  --darker: rgba(45, 66, 102, 0.1);
  --shadow-modal-color: rgba(0, 0, 0, 0.09);
  --shadow-card: rgba(0, 0, 0, 0.12) 0px 10px 40px -10px;
  --shadow-card-highlight: rgba(0, 0, 0, 0.3) 0px 10px 40px -10px;
  --shadow-modal: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  --shadow-modal-highlight: rgba(35, 37, 59, 0.6) 0px 20px 60px -10px;
  --shadow-watermark: 3px 3px 10px 3px rgba(56, 28, 100, 0.1);
  --shadow-navbar: 0 0 20px rgba(0, 0, 0, 0.04);
  --shadow-button: 0px 4px 22px rgba(0, 0, 0, 0.1);
  --shadow-button-purple: 0px 12px 16px -4px rgba(94, 93, 239, 0.12), 0px 4px 6px -2px rgba(94, 93, 239, 0.05);
  --shadow-hover-card: 0px 12px 16px -4px rgba(16, 24, 40, 0.05), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-sm: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  --shadow-md: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  --shadow-lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  --shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  --highlight: rgba(0, 0, 0, 0.05);
  --highlight-highlight: rgba(0, 0, 0, 0.1);
  --highlight-2: rgba(0, 0, 0, 0.15);
  --highlight-3: rgba(0, 0, 0, 0.3);
  --border-modal: rgb(239, 241, 244);
  --border-modal-transparent: rgb(239, 241, 244, 0.3);
  --border-highlight: rgb(231, 234, 238);
  /* --text: #3c4149; */
  --text: #000000;
  --side-background: #ffffff;
  --side-text: #3c4149;
  --text-sharp: #282a30;
  --text-light: #6b6f76;
  --text-toolbar: #6b6f76;
  --text-lighter: #929cad;
  --text-lighter2: rgb(191, 199, 214);
  --xraymode-border: #9393ff;
  /*
  --accent: #6e79d6;
  --accent-transparent: rgb(113, 124, 225, 0.2);
  --accent-semi-transparent: rgb(113, 124, 225, 0.5);
  --accent-highlight: rgb(113, 124, 225);
  */
  --accent: #5e5df0;
  --accent-text: #5e5df0;
  --accent-dark: #3f3fd3;
  --accent-light: #efeffe;
  --accent-transparent: rgba(93, 93, 240, 0.2);
  --accent-semi-transparent: rgb(93, 93, 240, 0.5);
  --accent-highlight: #7f7ffc;
  --text-on-accent: #ffffff;

  /* input */
  --background-input: #ffffff;
  --border-input: rgb(223, 225, 228);
  --border-input-focused: rgb(33, 97, 220);
  --border-radius-input: 15px;

  /* inverts */
  --background-invert: #131415;
  --background-invert-highlight: #2d2f36;
  --text-invert: #d7d8db;
  --text-invert-light: #8a8f98;
  --text-invert-sharp: #fff;

  /* mobile links */
  --card-border-radius: 20px;
  --platform-accent: #007aff;
  --background-delete-transparent: rgba(227, 227, 227, 0.65);

  /* colors */
  --danger: rgb(255, 56, 96);
  --danger-transparent: rgba(253, 196, 207, 0.3);
  --danger-soft: #ff839c;
  --danger-transparent: rgb(254, 236, 240, 0.3);
  --danger-background: rgb(254, 236, 240);
  --green: rgb(72, 199, 116);
  --green-transparent: rgba(153, 255, 189, 0.3);
  --dark-green: #218d7f;

  /* dropline */
  --dropline: #5e9cee;
  --dropBackground: rgba(156, 232, 255, 0.45);
  --dropBackgroundLight: rgba(156, 232, 255, 0.08);

  /* global */
  --text-white-light: #6b6f76;
  --text-white-lighter: #929cad;
  --background-white-highlight: #f0f3f9;
  --background-white-highlight-highlight: #e5eaf5;
  --dark-transparent: rgb(39, 40, 43, 0.6);
  --light-transparent: rgb(215, 216, 219, 0.6);
  --background-input-dark: rgb(31, 32, 35);
  --border-input-dark: rgb(60, 63, 68);
  --border-input-focused-dark: rgb(100, 153, 255);
  --text-white: #d7d8db;
  --text-white-transparent: rgb(255, 255, 255, 0.8);
  --box-transition: opacity 0.3s,
    width 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  --background-light-theme: #ffffff;
  --text-light-theme: #000000;
  --background-dark-theme: #131415;
  --text-dark-theme: #d7d8db;

  --shadow-color-unicorn: #f18099;
  --shadow-color-nebula: #b664b0;
  --shadow-color-water: #67b7e5;
  --shadow-color-candy: #9d8eee;
  --shadow-color-melon: #5cd4ac;
  --shadow-color-orange: #fa9e34;
  --shadow-color-yellow: #f7cc5c;
  --shadow-color-green: #23cfa4;
  --shadow-color-blue: #06c1ff;
  --shadow-color-indigo: #6968f7;
  --shadow-color-purple: #8a24ff;
  --shadow-color-pink: #f54b94;
  --shadow-color-red: #f32e60;
  --shadow-color-black: #000000;
  --shadow-color-white: rgba(0, 0, 0, 0.2);
  --shadow-color-accent: #5e5df0;
  --shadow-large: rgba(60, 66, 87, 0.08) 0px 15px 35px 0px,
    rgba(0, 0, 0, 0.12) 0px 5px 15px 0px;

  --navbar-item-img-max-height: 3.75rem;


  /* Token to replace Bulma */
  --space-0: 0;
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.5rem;
  --space-6: 3rem;
}

[data-theme="dark"] {
  /* Color styles from figma */
  --neutral-900: #fff;
  --neutral-800: #CCCCCC;
  --neutral-700: #646668;
  --neutral-600: #9EA3A8;
  --neutral-500: #747A81;
  --neutral-400: #35383B;
  --neutral-300: #35383B;
  --neutral-200: #303335;
  --neutral-100: #26282B;
  --neutral-50: #1D1E20;
  --neutral-25: #242628;

  --green-25: #273530;
  --pink-25: #35272F;

  /* New color tokens */
  --color-border: var(--neutral-700);
  --color-border-light: var(--neutral-500);
  --color-text-light: var(--neutral-800);
  --color-surface-raised: var(--neutral-400);
  --color-background-input: var(--color-surface-raised);
  --elevation-surface-raised: 0px 0px 0px rgba(0, 0, 0, 0), 0px 1px 1px rgba(3, 4, 4, 0.5), 0px 0px 1px rgba(3, 4, 4, 0.5);

  --background: #131415;
  --background-transparent: rgb(19, 20, 21, 0.5);
  --background-blur: rgba(19, 20, 21, 0.7);
  /* --background in RGB */
  --background-highlight: rgb(44, 44, 48);
  --background-highlight-toolbar: rgb(57, 57, 65);
  --background-toolbar: rgb(44, 44, 48);
  --background-toolbar-transparent: rgb(44, 44, 48, 0.8);
  --background-highlight-transparent: rgba(66, 66, 66, 0.7);
  --background-highlight-highlight: rgb(57, 57, 65);
  /* --background-highlight-highlight: #32343d; */
  --background-button: #1f2124;
  /* --background-button: rgb(39, 40, 43); */
  --background-modal: linear-gradient(136.61deg,
      rgb(39, 40, 43) 13.72%,
      rgb(45, 46, 49) 74.3%);
  --background-discount-modal: linear-gradient(180deg,
      rgb(69, 69, 100) 0%,
      rgb(6, 6, 63) 100%);
  --darker: rgb(0, 0, 0);
  --background-modal-highlight: rgb(55, 55, 60);
  --background-popup-overlay: rgba(28, 29, 31, 0.5);
  --shadow-modal-color: rgba(0, 0, 0, 0.2);
  --shadow-modal: rgba(0, 0, 0, 0.35) 0px 7px 32px 0px;
  --shadow-modal-highlight: rgba(0, 0, 0, 1) 0px 20px 60px -10px;
  --shadow-card: rgba(0, 0, 0, 0.4) 0px 10px 40px -10px;
  --shadow-card-highlight: rgba(0, 0, 0, 0.8) 0px 10px 40px -10px;
  --shadow-watermark: 3px 3px 10px 3px rgba(0, 0, 0, 0.4);
  --shadow-navbar: 0 0 20px rgba(0, 0, 0, 0.4);
  --shadow-button: 0px 4px 22px rgba(0, 0, 0, 0.4);
  --highlight: rgba(255, 255, 255, 0.05);
  --highlight-highlight: rgba(255, 255, 255, 0.1);
  --highlight-2: rgba(255, 255, 255, 0.2);
  --highlight-3: rgba(255, 255, 255, 0.3);
  --border-modal: rgb(48, 50, 54);
  --border-highlight: rgb(50, 53, 59);
  --text: #ffffff;
  --side-background: #131415;
  --side-text: #d7d8db;
  --text-toolbar: #d7d8db;
  --text-light: #9a9fa8;
  --text-lighter: #8b8e9b;
  --text-lighter2: #494e5e;
  --text-sharp: #ffffff;
  --accent: #8383ff;
  --accent-dark: #5959dc;
  /* #5e6ad2; */
  --accent-text: #acacff;
  --accent-light: #1d1e33;
  --accent-transparent: rgb(115, 129, 255, 0.2);
  /* #5e6ad2; */
  --accent-semi-transparent: rgb(115, 129, 255, 0.5);
  /* #5e6ad2; */
  --accent-highlight: #8995ff;
  /*rgb(113, 124, 225);*/
  --text-on-accent: #ffffff;
  --background-publish-purple: var(--primary-300);

  /* mobile links */
  --background-delete-transparent: rgba(92, 92, 92, 0.65);

  /* input */
  --background-input: rgb(31, 32, 35);
  --border-input: rgb(60, 63, 68);
  --border-input-focused: rgb(100, 153, 255);

  /* inverts */
  --background-invert: #ffffff;
  --background-invert-highlight: #f0f3f9;
  --text-invert: #3c4149;
  --text-invert-light: #6b6f76;
  --text-invert-sharp: #000;

  /* dropline */
  --dropline: #b4d5ff;
  --dropBackground: rgba(156, 232, 255, 0.18);
  --dropBackgroundLight: rgba(156, 232, 255, 0.05);

  /* colors */
  --danger: rgb(255, 56, 96);
  --green-transparent: rgba(75, 129, 107, 0.3);
  --danger-transparent: rgb(204, 15, 53, 0.3);
  --danger-background: rgb(92, 32, 44);
  --shadow-color-unicorn: #000;
  --shadow-color-nebula: #000;
  --shadow-color-water: #000;
  --shadow-color-candy: #000;
  --shadow-color-melon: #000;
  --shadow-color-orange: #000;
  --shadow-color-yellow: #000;
  --shadow-color-green: #000;
  --shadow-color-blue: #000;
  --shadow-color-indigo: #000;
  --shadow-color-purple: #000;
  --shadow-color-pink: #000;
  --shadow-color-red: #000;
  --shadow-color-black: #000;
  --shadow-color-white: #000;
  --shadow-color-accent: #000;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, "Helvetica", "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Helvetica Neue", "Noto Sans", sans-serif;
  background-color: var(--background) !important;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 16px;
}

html {
  overflow-y: auto !important;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--background-highlight-highlight);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

div::-webkit-scrollbar-thumb {
  background-color: var(--background-highlight-highlight);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

td::-webkit-scrollbar-thumb {
  background-color: var(--background-highlight-highlight);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

body::-webkit-scrollbar {
  width: 16px;
}

div::-webkit-scrollbar {
  width: 16px;
}

td::-webkit-scrollbar {
  width: 16px;
}


.noscroll {
  overflow-y: hidden !important;
}

/*
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif; */

.with-background-color {
  background-color: var(--background);
}

.with-button-background-color {
  background-color: var(--background-button);
}

.with-side-background-color {
  background-color: var(--side-background);
}

.with-highlight-background-color {
  background-color: var(--background-highlight) !important;
}

.with-text-color {
  color: var(--text);
}

.with-side-text-color {
  color: var(--side-text);
}

.with-accent-background-color {
  background-color: var(--accent);
}

.with-accent-transparent-background-color {
  background-color: var(--accent-transparent) !important;
}

.with-accent-text-color {
  color: var(--accent);
}

.with-accent-border-color {
  border-color: var(--accent) !important;
}

.with-text-on-accent-text-color {
  color: var(--text-on-accent);
}

.with-light-text-color {
  color: var(--text-light);
}

.with-lighter-text-color {
  color: var(--text-lighter);
}

.with-lighter-2-text-color {
  color: var(--text-lighter2);
}

.with-sharp-text-color {
  color: var(--text-sharp);
}

.with-danger-text-color {
  color: var(--danger) !important;
}

.with-danger-transparent-background-color {
  background-color: var(--danger-transparent) !important;
}

.with-danger-background-color {
  background-color: var(--danger-background) !important;
}

.with-green-text-color {
  color: var(--green) !important;
}

.with-green-background-color {
  background-color: var(--green) !important;
}

.with-green-transparent-background-color {
  background-color: var(--green-transparent) !important;
}

.with-dark-green-text-color {
  color: var(--dark-green);
}

.with-cursor-pointer {
  cursor: pointer;
}

/* ========================================= */
/*              Global Styles                */
/* ========================================= */

hr {
  background-color: var(--text-lighter) !important;
  height: 1px !important;
  opacity: 0.2;
  width: 100%;
}

.box-border {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-button);
  box-shadow: var(--shadow-modal);
  border: 1px solid var(--border-modal);
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  cursor: pointer;
}

.box-border-only {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-button);
  box-shadow: var(--shadow-modal);
  border: 1px solid var(--border-modal);
  border-radius: 4px;
  cursor: pointer;
}

.box-no-border {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-button);
  box-shadow: var(--shadow-modal);
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  cursor: pointer;
}

.box-border-no-shadow {
  border: 1px solid var(--neutral-300) !important;
  border-radius: 8px;
}

.card-border {
  padding: 16px 20px;
  border: 1px solid var(--border-modal);
  border-radius: 6px;
}

.has-fullwidth {
  width: 100%;
}

.has-fit-content-width {
  width: fit-content;
}

.loader.is-loading.with-accent-border-color {
  border-bottom-color: var(--accent) !important;
  border-left-color: var(--background-highlight) !important;
}

.is-subtitle-size {
  font-size: 15px;
}

.is-small-subtitle-size {
  font-size: 13px;
}

.is-size-5 {
  letter-spacing: -0.5px;
}

.is-size-4 {
  letter-spacing: -0.7px;
  line-height: normal;
}

.is-size-3 {
  letter-spacing: -0.1rem;
}

.is-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-subtitle {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-lighter);
}

.is-small-subtitle {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-lighter);
}

a:hover {
  color: var(--text);
}

.switch[type="checkbox"].is-small+label::before,
.switch[type="checkbox"].is-small+label:before {
  background: var(--background-highlight-highlight) !important;
}

.switch[type="checkbox"].is-primary:checked+label::before,
.switch[type="checkbox"].is-primary:checked+label:before {
  background: var(--accent) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ========================================= */
/*              Content Styles               */
/* ========================================= */
[contenteditable] {
  outline: 0px solid transparent;
}

/*
.options-container-trigger:hover > .block-element-container > .options-handle {
  visibility: visible !important;
  opacity: 1 !important;
} */
.content {
  width: 100%;
}

.content .slate-align-center {
  text-align: center !important;
}

.content .slate-align-right {
  text-align: right !important;
}

.content a span {
  cursor: pointer;
}

.content span {
  word-break: break-word;
  cursor: text;
}

.content ol:not(:last-child),
.content ul:not(:last-child) {
  margin-bottom: 0 !important;
}

.content ol,
.content ul {
  margin-left: 0 !important;
  margin-top: 0 !important;
  width: 100%;
}

.content ul {
  list-style: disc outside;
}

.content ul ul {
  list-style: circle;
}

.content ul ul ul {
  list-style: square;
}

.content blockquote {
  width: 100%;
}

.content :not(pre)>code {
  background-color: var(--background-highlight);
  color: var(--text-light);
}

/* .content pre {
  background-color: var(--background-button);
  width: 100%;
  border: 1px solid var(--border-modal);
} */
.content pre {
  background-color: var(--background-button);
  width: 100%;
  box-shadow: var(--shadow-modal);
  border-radius: 10px;
}

.content a.slate-a {
  color: var(--accent);
}

.content a.slate-a:hover {
  text-decoration: underline;
}

.content .tabs ul,
.content .tabs ul li a {
  border-bottom-color: var(--border-modal);
}

.content .tabs ul li.is-active a {
  border-bottom-color: var(--accent);
}

.content .tabs ul li {
  margin-left: 10px !important;
}

/* TODO: Move to config if possible */
h1, .content h1[data-slate-node="element"] {
  font-size: 56px;
  font-weight: 800;
  letter-spacing: -2.5px;
}

h2, .content h2[data-slate-node="element"] {
  font-size: 36px;
  font-weight: 800;
  letter-spacing: -1px;
}

h3, .content h3[data-slate-node="element"] {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

@media only screen and (max-width: 768px) {
  .content h1[data-slate-node="element"] {
    font-size: 40px;
  }

  .content h2[data-slate-node="element"] {
    font-size: 30px;
  }

  .content h3[data-slate-node="element"] {
    font-size: 23px;
  }
}

.content p[data-slate-node="element"] {
  padding-top: 4px;
  padding-bottom: 4px;
}


.block-element-container:hover pre.slate-code-block::after {
  content: "exit: ⌘↵";
  position: absolute;
  font-family: "Inter";
  right: 10px;
  bottom: 10px;
  font-size: 0.7em;
  text-align: right;
  color: var(--text-light);
}

.windows .block-element-container:hover pre.slate-code-block::after {
  content: "exit: Ctrl ↵";
}

.selectable-container-root:hover pre.slate-code-block::after {
  right: 40px;
}

.content input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}

.content pre .number {
  display: inline;
  background: none;
  font-size: inherit;
  padding: 0px;
  margin: 0px;
  min-width: initial;
  height: auto;
}

.content pre .number span {
  display: inline;
  height: auto;
}

.content input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 1px solid var(--text-light);
  border-radius: 3px;
  background-color: var(--background);
}

.content input[type="checkbox"]:checked:before {
  background-color: var(--accent);
}

.content input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid var(--text-on-accent);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.slate-todo-list {
  padding: 0px !important;
}

/* ========================================= */
/*           Editor Global Styles            */
/* ========================================= */

.editor>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-x: initial;
}

.block-element-container:hover .slate-gutter-left,
.block-element-container:focus .slate-gutter-left {
  opacity: 1 !important;
}

div[class^="slate-align"] {
  width: 100%;
}

.editor.xray div::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 1px dotted var(--text-lighter);
  opacity: 0.05;
  user-select: none;
  z-index: 0;
}

/* ========================================= */
/*          Hovering Toolbar Styles          */
/* ========================================= */
.HoveringToolbar_Menu .slate-ToolbarButton {
  color: var(--text) !important;
  padding: 8px 4px !important;
  height: 20px;
  width: 20px;
  box-sizing: content-box !important;
}

.HoveringToolbar_Menu .slate-ToolbarButton-active {
  color: var(--accent) !important;
}

.HoveringToolbar_Menu .slate-ToolbarButton:hover {
  background-color: var(--background-highlight);
}

/* ========================================= */
/*          Docked Toolbar Styles          */
/* ========================================= */
.DockToolbar .slate-ToolbarButton {
  color: var(--text) !important;
  padding: 4px 4px !important;
  height: 20px;
  width: 20px;
  box-sizing: content-box !important;
  border-radius: 999px;
}

.DockToolbar .slate-ToolbarButton-active {
  color: var(--accent) !important;
  background-color: var(--background-highlight);
}

.DockToolbar .slate-ToolbarButton:hover {
  background-color: var(--background-highlight);
}

/* ================================================ */
/*     ToolbarToolbar_Menu Toolbar Styles          */
/* ================================================ */
.ToolbarToolbar_Menu .slate-ToolbarButton {
  color: var(--text) !important;
  padding: 4px 4px !important;
  height: 20px;
  width: 20px;
  box-sizing: content-box !important;
  border-radius: 999px;
}

.SideToolbar .slate-ToolbarButton {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 4px 4px !important;
  cursor: pointer;
}

.ToolbarToolbar_Menu .slate-ToolbarButton-active {
  color: var(--accent) !important;
  background-color: var(--background-highlight);
}

.SideToolbar .slate-ToolbarButton-active {
  color: var(--accent) !important;
  background-color: var(--background-highlight-toolbar);
}

.SideToolbar .slate-ToolbarButton:hover,
.ToolbarToolbar_Menu .slate-ToolbarButton:hover {
  background-color: var(--background-highlight);
}

/* ========================================= */
/*          Resizable Block Styles           */
/* ========================================= */
.resizable-container {
  background: none !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  box-shadow: none !important;
}

.resizable-container-border:hover,
.resizable-container-border-hover {
  border: 1px solid var(--accent-semi-transparent) !important;
}

.resizable-container img {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: inherit;
  max-height: inherit;
  object-fit: contain;
}

.resizable-container .slate-Selectable {
  flex-grow: 1;
  display: flex;
  position: relative;
  width: 100% !important;
  height: 100% !important;
}

.resizable-container .slate-Selectable>div[class^="block"] {
  flex-grow: 1;
  display: flex;
}

.resizable-container .slate-img,
.resizable-container .slate-img div {
  flex-grow: 1;
  height: 100%;
  max-height: 100% !important;
  user-drag: none;
  user-select: none;
  -webkit-user-drag: none;
}

.custom-handle-wrapper {
  position: absolute;
  border-radius: 999px;
  z-index: 5;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.custom-handle {
  background-color: var(--accent);
  border: 1px solid var(--light-transparent);
  opacity: 0.75;
  border-radius: 999px;
  height: 10px;
  width: 10px;
}

.custom-handle-w,
.custom-handle-e {
  top: 50%;
  margin-top: -9px;
}

.custom-handle-cursor-w,
.custom-handle-cursor-e {
  cursor: ew-resize;
}

.custom-handle-w {
  left: -9px;
}

.custom-handle-e {
  right: -9px;
}

.custom-handle-n,
.custom-handle-s {
  left: 50%;
  margin-left: -9px;
}

.custom-handle-cursor-s,
.custom-handle-cursor-n {
  cursor: ns-resize;
}

.custom-handle-n {
  top: -9px;
}

.custom-handle-s {
  bottom: -9px;
}

/* .custom-handle-ne,
.custom-handle-sw,
.custom-handle-nw,
.custom-handle-se {
  background: none;
  border: none;
  color: rgb(55, 53, 47);
  fill: rgb(55, 53, 47);
  outline-color: rgb(55, 53, 47);
} */

/* .custom-handle-sw {
  transform: rotate(90deg);
}
.custom-handle-nw {
  transform: rotate(180deg);
}
.custom-handle-ne {
  transform: rotate(270deg);
} */
.custom-handle-cursor-ne,
.custom-handle-cursor-sw {
  cursor: nesw-resize;
}

.custom-handle-cursor-nw,
.custom-handle-cursor-se {
  cursor: nwse-resize;
}

.custom-handle-nw,
.custom-handle-sw {
  left: -9px;
}

.custom-handle-ne,
.custom-handle-se {
  right: -9px;
}

.custom-handle-nw,
.custom-handle-ne {
  top: -9px;
}

.custom-handle-sw,
.custom-handle-se {
  bottom: -9px;
}

/* ========================================= */
/*                 Animation                 */
/* ========================================= */
.fade-in-from-bottom {
  animation: fadeIn ease 0.4s;
  -webkit-animation: fadeIn ease 0.4s;
  -moz-animation: fadeIn ease 0.4s;
  -o-animation: fadeIn ease 0.4s;
  -ms-animation: fadeIn ease 0.4s;
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0.3;
    margin-top: 10px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-moz-keyframes fadeInFromBottom {
  0% {
    opacity: 0.3;
    margin-top: 10px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-webkit-keyframes fadeInFromBottom {
  0% {
    opacity: 0.3;
    margin-top: 10px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-o-keyframes fadeInFromBottom {
  0% {
    opacity: 0.3;
    margin-top: 10px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-ms-keyframes fadeInFromBottom {
  0% {
    opacity: 0.3;
    margin-top: 10px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-wrapper .loader-box,
.loader-box-big {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 30px;
}

.loader-wrapper .loader {
  height: 40px;
  width: 40px;
  border-bottom-color: var(--accent);
}

.loader-big {
  height: 40px !important;
  width: 40px !important;
  border-bottom-color: var(--accent);
}

.loader-small-wrapper {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.loader-wrapper.is-active {
  opacity: 1;
  z-index: 5;
}

.is-loading {
  position: relative;
}

.loader {
  -webkit-animation: spinAround 500ms infinite linear;
  animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  height: 1em;
  width: 1em;
}

@media screen and (min-width: 1023px) {
  .lg-smallpadding {
    padding: 0px 5px !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transform: scale(0.7);
  transition: transform 300ms ease-in-out;
}

.ReactModal__Content--before-close {
  transform: scale(0.7);
}

.ReactModal__Content--after-open {
  transform: scale(1);
}

.ReactModal__Content::webkit-scrollbar-thumb {
  background-color: var(--text-light);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.ReactModal__Content::-webkit-scrollbar {
  width: 16px;
}

.scrollbox {
  overflow-y: auto;

  background:
    /* Shadow covers */
    linear-gradient(var(--background-modal) 30%,
      rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), var(--background-modal) 70%) 0 100%,
    /* Shadows */
    linear-gradient(rgba(64, 59, 122, 0.1), rgba(0, 0, 0, 0)),
    linear-gradient(rgba(0, 0, 0, 0), rgba(64, 59, 122, 0.1)) 0 100%;
  background-repeat: no-repeat;
  background-color: var(--background-modal);
  background-size: 100% 80px, 100% 80px, 100% 25px, 100% 25px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

/* ================================================= */
/*                  ANIMATION STYLES                 */
/* ================================================= */
.animated-block-initial {
  opacity: 0;
}

.animated-block-visible {
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animated-block-visible.slide-in-down {
  animation-name: slide-in-down;
}

.animated-block-visible.slide-in-up {
  animation-name: slide-in-up;
}

.animated-block-visible.slide-in-left {
  animation-name: slide-in-left;
}

.animated-block-visible.slide-in-right {
  animation-name: slide-in-right;
}

.animated-block-visible.fade-in {
  animation-name: fade-in;
}

.animated-block-visible.scale-in-grow {
  animation-name: scale-in-grow;
}

.animated-block-visible.scale-in-shrink {
  animation-name: scale-in-shrink;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  20% {
    opacity: 15%;
  }

  40% {
    opacity: 1;
    transform: translateY(-3px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slide-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  20% {
    opacity: 15%;
  }

  40% {
    opacity: 1;
    transform: translateY(3px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  20% {
    opacity: 15%;
  }

  40% {
    opacity: 1;
    transform: translateX(-3px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  20% {
    opacity: 15%;
  }

  40% {
    opacity: 1;
    transform: translateX(3px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}


@keyframes scale-in-grow {
  0% {
    opacity: 0;
    transform: scale(50%);
  }

  20% {
    opacity: 15%;
  }

  40% {
    opacity: 1;
    transform: scale(103%);
  }

  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

@keyframes scale-in-shrink {
  0% {
    opacity: 0;
    transform: scale(150%);
  }

  20% {
    opacity: 15%;
  }

  40% {
    opacity: 1;
    transform: scale(97%);
  }

  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

/* Text Animation Scroll */

.scroll-wrapper {
  overflow: hidden;
  display: block;
  position: absolute;
  left: 0;
  white-space: nowrap;
  height: 110%;
}

.scroll-wrapper span {
  display: block;
  position: absolute;
  /* line-height: var(--line-height); */
  top: 0;
  left: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: .5s opacity ease-in-out;
  white-space: nowrap;
}

.scroll-wrapper span.animate-show {
  transform: translateY(0%);
  transition: .5s all ease-in-out;
  opacity: 1 !important;
}

.scroll-wrapper span.animate-up {
  transform: translateY(-100%);
  transition: .5s all ease-in-out;
  opacity: 0;
}

img.darken,
video.darken {
  filter: brightness(0.6);
}
